import styled from "styled-components";
import { colors } from "../../constants/themeConstants";

export const NavbarWrapper = styled.header`
  &.header {
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(21, 30, 40, 0.08);
    padding: 14px 0;
    z-index:999;      
      position: sticky; 
      top: 0;      
      .container {
      
      padding: 0 15px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: none;
    }

     style={{  style={{ position: "sticky", top: 0, zIndex: 1000 }}, top: 0, zIndex: 1000 }}
    .main-logo {
      width: 231px;
      @media (min-width: 768px) {
        width: 361px;
      }
      @media (min-width: 1024px) {
        width: 432px;
      }
      img {
        border: none;
        height: auto;
        max-width: 80%;
        vertical-align: top;
      }
      a {
        display: block;
      }
    }
    .userBox-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .navbar-nav {
        margin: 0;
        list-style-type: none;
        margin: 0 16px;
        .nav-link {
          text-decoration: none;
          color: ${colors.gray};
          font-weight: 500;
          display: flex;
          .material-icons {
            margin-right: 3px;
            transition-duration: 0.3s;
            transition-property: background, color, opacity, visibility, border,
              box-shadow, border-radius;
            transition-timing-function: ease-in-out;
          }
          &:hover {
            color: ${colors.secondary};
            .material-icons {
              color: ${colors.secondary};
            }
          }
        }
      }
    }
  }
`;

export const StyledDropdown = styled.div`
  &.user-block {
    margin-left: 8px;
    position: relative;
    .user-opener {
      background: none;
      cursor: pointer;
      border: none;
      color: ${colors.secondary};
      display: block;
      font-size: 16px;
      font-weight: 700;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: inherit;
      .username {
        text-transform: capitalize;
      }
      .logo {
        height: 25px;
        width: 25px;
        z-index: 9999999999999;
      }
    }
    .dropdown {
      background: ${colors.white};
      border-radius: 4px;
      box-shadow: -10px -10px 40px 0 rgba(21, 30, 40, 0.08);
      list-style: none;
      margin: 10px 0 0;
      opacity: 0;
      padding: 0;
      position: absolute;
      right: 40px;
      top: 20px;
      visibility: hidden;
      width: 120px;
      z-index: 100;
      // &:before {
      //   border-color: transparent transparent ${colors.white};
      //   border-style: solid;
      //   border-width: 0 8px 8px;
      //   bottom: 100%;
      //   content: "";
      //   height: 0;
      //   position: absolute;
      //   right: 28px;
      //   width: 0;
      // }
      button {
        background: none;
        border: none;
        border-radius: 4px;
        display: block;
        padding: 6px 15px;
        text-align: center;
        width: 100%;
        cursor: pointer;
        &:hover {
          background-color: ${colors.secondary};
          color: ${colors.white};
        }
      }
    }
    &.active {
      opacity: 1;
      visibility: visible;
      .dropdown {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

export const StyledSettingDropdown = styled.div<{ hasdata: any }>`
  &.setting-block {
    margin-left: 8px;
    position: relative;
    .setting-opener {
      background: none;
      cursor: pointer;
      border: none;
      color: ${colors.secondary};
      display: block;
      font-size: 14px;
      font-weight: 700;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: inherit;
      .setting-icon:hover{
      color: ${colors.secondary};
      }
      &:hover {
        // background-color: ${colors.secondary};
        color: ${colors.secondary};
        .material-ic.viewWrapper {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }
      display: flex;
       {
        color: ${colors.secondary};
      }
    }
  }
  .notif-opener {
    background: none;
    cursor: pointer;
    border: none;
    color: ${colors.secondary};
    display: block;
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: inherit;
    &:hover {
      color: ${colors.secondary};
      .material-icons {
        color: ${colors.secondary};
      }
    }

    .unread {
      position: absolute;
      background-color: red;
      color: red;
      padding: 5px;
      border-radius: 50%;
      top: 0;
      right: 9px;
      z-index: 9999;
    }
  }

  .setting-dropdown {
    background: ${colors.white};
    border-radius: 4px;
    box-shadow: -10px -10px 40px 0 rgba(30, 40, 50, 0.1);
    list-style: none;
    margin: 20px 0 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: -130px;
    top: 9px;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    visibility: hidden;
    width: 200px;
    z-index: 100;
    // &:before {
    //   border-color: transparent transparent ${colors.white};
    //   border-style: solid;
    //   border-width: 0 8px 8px;
    //   bottom: 100%;
    //   content: "";
    //   height: 0;
    //   position: absolute;
    //   right: 145px;
    //   width: 0;
    // }
    button {
      background: none;
      border: none;
      border-radius: 4px;
      display: block;
      padding: 1px 10px;
      text-align: left;
      width: 200px;
      cursor: pointer;
      &:hover {
        background-color: ${colors.secondary};
        font-color: ${colors.white};
      }
    }
    .icon {
      vertical-align: middle;
    }
    .link {
      text-decoration: none;
      color: black;
      &:hover {
        color: white;
      }
    }
    .setting-label {
      margin: 6px;
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
    .setting-dropdown {
      opacity: 1;
      visibility: visible;
    }
  }

  .notif-dropdown {
    background: ${colors.white};
    border-radius: 4px;
    box-shadow: -10px -10px 40px 0 rgba(30, 40, 50, 0.1);
    list-style: none;
    padding: 0px;
    display: block;
    position: absolute;
    right: -70px;
    top: 36px;
    transition: 0s 0s;  
      visibility: hidden;
    width: 350px;
    max-height: ${({ hasdata }) => (hasdata ? "370px" : "450px")};
    height: ${({ hasdata }) => (hasdata ? "auto" : "auto")};

    overflow-y: auto;
    z-index: 900;
    // &:before {
    //   border-color: transparent transparent ${colors.white};
    //   border-style: solid;
    //   border-width: 0 8px 8px;
    //   bottom: 100%;
    //   content: "";
    //   height: 0;
    //   position: absolute;
    //   right: 77px;
    //   width: 0;
    //   z-index: 9999;
    //   transition: 0s 0s;

    // }
    &:hover{
      visibility:visible;
      transtion-delay:0s;
    }

    .notif-header {
      display: flex;
      align-items: space-between;
      border-bottom: 1px solid ${colors.lightGray};
      position: sticky;
      z-index: 9999;
      top: 0;
      background-color: white;
      transition: 0s 0s;
    }

    // .notif-body {
    //   display: flex;
    //   align-items: space-between;
    //   flex-direction: column;
    //   border-bottom: 1px solid ${colors.lightGray};
    //   position: sticky;
    //   top: 0;
    //   transition: 0s 0s;
    // }

    .notif-title {
      width: 60%;
      position: sticky;
      bottom: 100%;
      background: ${colors.white};
      margin-left: 10px;
      font-size: 25px;
      font-weight: 600;
      transition: 0s 0s;
    }
    .notif-action {
      display: flex;
      position: sticky;
      background: ${colors.white};
      margin-left: 10px;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;
      align-items: flex-end;
      transition: 0s 0s;
    }

    .item-button {
      background: none;
      border: none;
      border-radius: 4px;
      display: block;
      width: 100%;
      color: black;
      cursor: pointer;
      white-space: nowrap;
      text-align: left;
      padding-left:10px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      transition: 0s 0s;

    
      .notif-message,
      .notif-label,
      .notif-date {
        padding: 0px 7px;
        margin: 0px;
        overflow-x: hidden;
        text-overflow: ellipsis ;
        position: relative;
        left: 0;
        &.notif-date {
          font-size: 10px;
        }
        transition: 0s 0s;
      }
      &:hover {
        color: white;
        background-color: ${colors.secondary};
      }
    } 

      .all-notif {
        display: flex;
        justify-content: center;
        border-radius: 4px;
        color: black;
        background: ${colors.white};
        z-index: 90;
        // border-top: 1px solid ${colors.lightGray};
        padding: 6px 5px;
        font-size: 15px;
        font-weight: 600;
        height: 35px;
        transition: 0s 0s;
        &:hover {
          background: ${colors.lightGray};
          color: ${colors.white};
        }
      }

      .no-notif {
        display: flex;
        justify-content: center;
        font-size: 20px;
        transition: 0s 0s;
      }
      .no-notif-label {
        transition: 0s 0s;
      }
    }
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(23, 45, 85, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background: #172d55;
    }

    &.active {
      opacity: 1;
visibilityL: visible;
      .notif-dropdown,
      .notif-header,
      .notif-title,
      .notif-action,
      .item-button,
      .notif-message,
      .notif-label,
      .notif-date,
      .all-notif,
      .no-notif,
      .no-notif-label {
        opacity: 1;
        visibility: visible;
      }

      .notif-dropdown {
        .all-notif {
          border-top: 1px solid ${colors.black};
        }
      }
    }
  }
`;
