import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../pages/auth/authSlice";
import notifyReducer from "../pages/auth/notify";
import routeReducer from "../pages/configuration/routeSlice";
import SubSegmentState from "./slices/subSegmentSlice";
import GrowthOpportunitiesReducer from "./slices/GrowthSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    route: routeReducer,
    notify: notifyReducer,
    subSegment: SubSegmentState,
    growthOpportunitiesSlice: GrowthOpportunitiesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
