import html2canvas from "html2canvas";
import _ from "lodash";
import { PERMISSION_LIST } from "../constants/common";
import { OptionType } from "../types/common";
// Converts Strings Data with value to groups objects
const getCustomerPolicies = (
  policies: any,
  permissionIdentifier?: string
): string[] =>
  Object.keys(policies)
    .map((item) => {
      return item;
    })
    .filter((item) => {
      const permission = PERMISSION_LIST.map((item) => item.id);
      return permission.includes(item.split(".")[0]);
    });

export const restructureNonFieldPermissions = (
  data: string[]
  // permissionIdentifier?: string
): any => {
  return data?.map((item: string) => {
    const permissionParts = item
      // .replaceAll(permissionIdentifier, "")
      .split(".");
    return {
      groupKey: permissionParts[0],
      key: item,
      text: permissionParts.length > 1 ? permissionParts[1] : "View",
    };
  });
};

export const getFilteredPermissionData = (
  policies: any,
  permissionIdentifier?: any
): any => {
  const customerPolicies = getCustomerPolicies(policies, permissionIdentifier);
  const nonFieldLevelPermissionsData = restructureNonFieldPermissions(
    customerPolicies
    // permissionIdentifier
  );

  const nonFieldLevelPermissionsGroups = _.groupBy(
    nonFieldLevelPermissionsData,
    (item) => item.groupKey
  );
  return { nonFieldLevelPermissionsGroups };
};

export const getFilterSelectedLabel = (
  id: string,
  options: OptionType[] | undefined
) => {
  const filterSelectedLabel = options?.find(
    (item: OptionType) => item.value === id
  )?.label;
  return filterSelectedLabel?.toString();
};

export const getGradient = (a, ctx, chartArea, visualizationRanking) => {
  let gradient;
  if (
    isNaN(a?.x) ||
    isNaN(a?.y) ||
    isNaN(a?.width) ||
    typeof a?.x === "undefined" ||
    typeof a?.width === "undefined" ||
    typeof a?.y === "undefined"
  ) {
    return null; // or any default value you prefer
  }

  const gradientArray = visualizationRanking?.map((item, index) => {
    const width = chartArea.width;
    if (width) {
      switch (item?.durationCurve) {
        case "Linear":
          gradient = ctx.createLinearGradient(a.x - a.width, a.y, a.x, a.y);
          gradient.addColorStop(0.125, `rgba(219,220,222,1)`);
          gradient.addColorStop(0.25, `rgba(233,254,255,1)`);
          gradient.addColorStop(0.375, `rgba(155,217,254,1)`);
          gradient.addColorStop(0.5, `rgba(109,198,254,1)`);
          gradient.addColorStop(0.625, `rgba(72,183,255,1)`);
          gradient.addColorStop(0.75, `rgba(33,169,255,1)`);
          gradient.addColorStop(1, `rgba(17,119,255,1)`);

          return gradient;

        case "Polynomial":
          gradient = ctx.createLinearGradient(a.x - a.width, a.y, a.x, a.y);
          gradient.addColorStop(0, `rgba(219,220,222,1)`);
          gradient.addColorStop(0.3, `rgba(233,254,255,1)`);
          gradient.addColorStop(0.5, `rgba(72,183,255,1)`);
          gradient.addColorStop(1, "rgba(17,119,255,1)");
          gradient.addColorStop(0.5, `rgba(72,183,255,1)`);
          gradient.addColorStop(0.3, `rgba(233,254,255,1)`);

          return gradient;
        case "Logistic":
          gradient = ctx.createLinearGradient(a.x - a.width, a.y, a.x, a.y);
          gradient.addColorStop(0, `rgba(219,220,222,0.5)`);
          gradient.addColorStop(0.125, `rgba(233,254,255,1)`);
          gradient.addColorStop(0.25, `rgba(155,217,254,1)`);
          gradient.addColorStop(0.375, `rgba(109,198,254,1)`);
          gradient.addColorStop(0.5, `rgba(17,119,255,1)`);
          gradient.addColorStop(0.625, `rgba(17,119,255,1)`);
          gradient.addColorStop(0.75, `rgba(17,119,255,1)`);
          // gradient.addColorStop(0.875, `rgba(109,198,254,1)`);
          gradient.addColorStop(1, `rgba(109,198,254,1)`);
          return gradient;
        case "Oscillatory":
          gradient = ctx.createLinearGradient(a.x - a.width, a.y, a.x, a.y);
          gradient.addColorStop(0, `rgba(219,220,222,0.5)`);
          gradient.addColorStop(0.16, `rgba(233,254,255,1)`);
          gradient.addColorStop(0.33, `rgba(72,183,255,1)`);
          gradient.addColorStop(0.5, "rgba(17,119,255,1)");
          gradient.addColorStop(0.66, `rgba(72,183,255,1)`);
          gradient.addColorStop(0.83, `rgba(233,254,255,1)`);
          gradient.addColorStop(1, `rgba(72,183,255,1)`);
          return gradient;
        case "Exponential":
          gradient = ctx.createLinearGradient(a.x - a.width, a.y, a.x, a.y);
          gradient.addColorStop(0, `rgba(219,220,222,1)`);
          gradient.addColorStop(0.125, `rgba(233,254,255,1)`);
          gradient.addColorStop(0.25, `rgba(197,235,254,1)`);
          gradient.addColorStop(0.375, `rgba(155,217,254,1)`);
          gradient.addColorStop(0.5, `rgba(109,198,254,1)`);
          gradient.addColorStop(0.625, `rgba(72,183,255,1)`);
          gradient.addColorStop(0.75, `rgba(33,169,255,1)`);
          gradient.addColorStop(1, `rgba(17,119,255,1)`);
          return gradient;
        default:
          gradient = ctx.createLinearGradient(a.x - a.width, a.y, a.x, a.y);
          gradient.addColorStop(0, `#e74c3c`);
          gradient.addColorStop(1, `#e74c3c`);
          return gradient;
      }
    }
  });

  return gradientArray;
};
function convertImageTo16by9(base64Image, aspectRatio) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const targetWidth = img.width;
      const targetHeight = Math.ceil(targetWidth / aspectRatio);
      canvas.width = targetWidth + 400;
      canvas.height = targetHeight;
      ctx?.drawImage(img, 0, 0, targetWidth + 400, targetHeight);
      resolve(canvas.toDataURL());
    };
    img.onerror = reject;
    img.src = base64Image;
  });
}
export const exportAsImage = async (
  el,
  imageFileName,
  aspectRatio,
  setExporting
) => {
  if (imageFileName === "Visualization" && el) {
    const chartTableStyle = el.querySelector(".rdt_TableBody");
    const chartStyle = el.querySelector(".chartStyle");
    const innerChartStyle = el.querySelector(".bar-chart");
    if (chartTableStyle) chartTableStyle.style.maxHeight = "100%";
    if (chartStyle) {
      chartStyle.style.zoom = 1;
      chartStyle.style.minHeight = "100vh";
      chartStyle.style.width = "50%";
      chartStyle.style.height = "90vh";
      chartStyle.style.margin = "40px 0 0 0";
    }
    if (innerChartStyle) {
      innerChartStyle.style.minHeight = "90vh";
      innerChartStyle.style.width = "100%";
      innerChartStyle.style.height = "85vh";
    }

    const canvas = await html2canvas(el);
    if (chartStyle) {
      chartStyle.style.minHeight = "100vh";
    }
    if (innerChartStyle) {
      innerChartStyle.style.minHeight = "90vh";
    }

    const image = canvas.toDataURL("image/png", 1.0);
    const downloadable = await convertImageTo16by9(image, aspectRatio);
    const newTab = window.open();
    const newImg = document.createElement("img");
    newImg.src = downloadable as string;
    newTab?.document.body.appendChild(newImg);
    downloadImage(downloadable, imageFileName);
    setExporting(false);
    newTab?.close();
  } else {
    const canvas = await html2canvas(el);

    const image = canvas.toDataURL("image/png", 2);
    const downloadable = await convertImageTo16by9(image, 16 / 9);
    const newTab = window.open();
    const newImg = document.createElement("img");
    newImg.src = downloadable as string;
    newTab?.document.body.appendChild(newImg);
    downloadImage(downloadable, imageFileName);
    setExporting(false);
    newTab?.close();
  }
};

export const downloadImage = (blob, fileName) => {
  const link = window.document.createElement("a");
  link.download = fileName;

  link.href = blob;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  link.remove();
};

export const replaceNullWithEmptyString = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, value ?? ""])
  );
};
